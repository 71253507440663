.mint_dialog > div:nth-child(3) > div {
    background: #1a1e21;
    padding: 40px;
    width: 400px;
}

.mint_dialog .mint_access {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mint_dialog .mint_access h2 {
    margin: 0;
    color: #ffffff;
}

.mint_dialog .mint_access > div {
    margin: 40px 0;
    width: 100%;
}

.mint_dialog .mint_access button {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    border-radius: 5px;
    text-transform: capitalize;
    padding: 10px 30px;
    margin-top: 10px;
    color: #000;
}
