.add_button {
    padding: 0 10px;
    cursor: pointer;
}

.add_button svg {
    width: 22px;
    height: 22px;
}

.collection_table .actions_campaign button {
    padding: 10px 15px;
    min-width: auto;
    margin-right: 0;
}

.actions_campaign button:first-child svg {
    width: 24px;
    height: 24px;
}

.actions_campaign button svg {
    width: 20px;
    height: 20px;
}

/* campaign_drawer */
.campaign_table .collection_icon {
    width: 100px;
    height: 80px;
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.campaign_drawer > div {
    width: 600px;
    padding: 20px;
    background: #1a1e21;
}

.campaign_drawer .drawer_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.campaign_drawer .drawer_list > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.campaign_drawer .drawer_list > div > span {
    width: 150px;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: left;
}

.campaign_drawer .drawer_list > div > p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff9c;
}

.campaign_drawer .drawer_list .denom_section {
    margin-top: 20px;
}

.campaign_drawer .drawer_list .denom_section span {
    font-size: 16px;
}

.campaign_drawer .drawer_list .denoms_map {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.campaign_drawer .drawer_list > div > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.campaign_drawer .drawer_list > div > div p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff9c;
    margin-left: 10px;
}
