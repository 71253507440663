.campaign_dialog > div:nth-child(3) > div {
    background: #1a1e21;
    padding: 40px;
    width: 600px;
}

.collection_table > div > div:first-child > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.campaign_dialog_section .campaign_fields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.campaign_dialog_section .campaign_fields > div {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 8px;
}

.campaign_dialog_section .campaign_fields > div.denom_select_field {
    width: 100%;
}

.campaign_dialog .date_field > div {
    border: 1px solid #3a3939;
    border-radius: 4px;
    /*margin: 25px 0 10px 0;*/
}

.campaign_dialog_section .campaign_fields input,
.campaign_dialog .date_field input {
    font-family: "Work Sans",sans-serif;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
}

.campaign_dialog .date_field fieldset {
    border: unset;
}

.campaign_dialog .title_list_field > div:nth-child(3) {
    border: 1px solid #3a3939;
    background: none;
    min-height: 54px;
    text-align: left;
    padding-left: 10px;
    box-shadow: none;
}

.campaign_dialog .title_list_field > div:nth-child(3):hover {
    border: 1px solid #3a3939;
    box-shadow: none;
}

.campaign_dialog .title_list_field > div:nth-child(3) > div:first-child > div:last-child {
    background: none;
}

.campaign_dialog .title_list_field.denoms_list > div:nth-child(3) > div:first-child > div,
.campaign_dialog .title_list_field.launchpad_denom > div:nth-child(3) > div:first-child > div {
    background: none;
    padding-left: 0;
    margin-left: 0;
}

.campaign_dialog .title_list_field input::placeholder {
    background: none;
}

.campaign_dialog .title_list_field input::-webkit-input-placeholder {
    background: none;
}

.campaign_dialog .title_list_field input::-ms-input-placeholder {
    background: none;
}

.campaign_dialog .title_list_field > div:nth-child(3) > div:first-child > div {
    background: #141414;
    border-radius: 15px;
    padding: 5px 10px;
}

.campaign_dialog .title_list_field > div:nth-child(3) > div:first-child > div > div {
    font-family: "Work Sans",sans-serif;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
}

.campaign_dialog .campaign_dialog_section .campaign_fields .denom_select_field {
    width: 100%;
}

.campaign_dialog .circular_progress {
    position: absolute;
}
