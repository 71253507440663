.launchpad_dialog.mint_dialog > div:nth-child(3) > div {
    width: 500px;
}

.launchpad_dialog.mint_dialog .mint_access > div {
    margin: 20px 0 0;
}

.launchpad_dialog.mint_dialog .mint_access .launchpad_url {
    margin-bottom: 20px;
}

.launchpad_dialog .buttons_div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.launchpad_dialog .mint_access button:disabled {
    opacity: 0.5;
}
