.navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0 8px 6px -6px #999;
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 101;
    background: #000;
}

.navbar .disconnect_button {
    color: #f2f2f2;
    width: 150px;
    background: #0e0f11;
    border: 1px solid #FAC01E;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
