.text_field label {
    color: #838383 !important;
    text-transform: capitalize;
}

.ff_studio .text_field label {
    color: #838383;
}

.text_field label + div:before,
.text_field label + div:after,
.text_field > div:before,
.text_field > div:after {
    border-bottom: 1px solid #3a3939;
}

.text_field fieldset,
.ff_studio .text_field > div:hover fieldset,
div[role='presentation'] .text_field > div:hover fieldset {
    border: 1px solid #3a3939;
}

.ff_studio .text_field label + div:hover:before,
.ff_studio .text_field label + div:hover:after,
.ff_studio .text_field > div:hover:before,
.ff_studio .text_field > div:hover:after,
div[role='presentation'] .text_field label + div:hover:before,
div[role='presentation'] .text_field label + div:hover:after {
    border-bottom: 1px solid #3a3939;
}

.text_field input,
.text_field textarea {
    color: #ffffff9e;
}

.ff_studio .text_field.error_text_field label + div:before,
.ff_studio .text_field.error_text_field label + div:after {
    border-color: #a62b2b;
}

.text_field.error_text_field label,
.text_field.error_text_field label {
    color: #a62b2b;
}

.text_field.error_text_field label + div:hover:before,
.text_field.error_text_field label + div:hover:after,
.text_field.error_text_field label + div:hover:before,
.text_field.error_text_field label + div:hover:after {
    border-color: #a62b2b;
}

.text_field .eye_icon {
    color: #ffffff75;
}

.text_field.field_margin {
    margin: 0;
}
