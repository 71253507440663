.banner_dialog > div:nth-child(3) > div {
    background: #1a1e21;
    padding: 40px;
    width: 600px;
}

.banner_dialog_section .banner_fields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.banner_dialog_section .banner_fields > div {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 8px;
}

.banner_dialog .date_field > div {
    border: 1px solid #3a3939;
    border-radius: 4px;
}

.banner_dialog_section .banner_fields input,
.banner_dialog .date_field input {
    font-family: "Work Sans",sans-serif;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
}

.banner_dialog .date_field fieldset {
    border: unset;
}

.banner_dialog .circular_progress {
    position: absolute;
}

.banner_dialog .file_upload input {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.banner_dialog .re_upload button {
    background: none;
    padding: 0;
    margin-top: 0;
    /*width: 100px;*/
    height: 50px
}

.banner_dialog .re_upload .uploaded_image {
    width: 100px;
    height: 50px;
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.banner_dialog .re_upload .re_upload_div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.banner_dialog .re_upload .re_upload_div p {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    border-radius: 5px;
    text-transform: capitalize;
    color: #000;
    align-items: center;
    padding: 10px;
}

/*.banner_dialog .re_upload > button .re_upload_div {*/
/*    display: none;*/
/*}*/

/*.banner_dialog .re_upload > button:hover .re_upload_div {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    background: #00000080;*/
/*}*/

/*.banner_dialog .re_upload > button .re_upload_div img {*/
/*    width: 14px;*/
/*    height: auto;*/
/*    margin-bottom: 4px;*/
/*}*/

/*.banner_dialog .re_upload > button .re_upload_div p {*/
/*    color: #FFF;*/
/*    font-family: 'Source Sans Pro', sans-serif;*/
/*    font-size: 6px;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    line-height: normal;*/
/*}*/

.banner_dialog .status_switch {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner_dialog .status_switch p {
    color: #FFF;
    font-family: Source Sans Pro,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    width: 30%;
}
