.banners_table > div > div:first-child > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.banners_table table tbody tr td .image {
    width: 100px;
    height: 80px;
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.banners_table table tbody tr td .redirection_url,
.banner_drawer .drawer_list .redirection_url > p {
    cursor: pointer;
    text-decoration: underline;
    font-style: italic;
}

/*banner drawer */
/*.banner_drawer .collection_icon {*/
/*    width: 100px;*/
/*    height: 80px;*/
/*    border-radius: 10px;*/
/*    background-size: contain;*/
/*    background-position: center center;*/
/*    background-repeat: no-repeat;*/
/*}*/

.banner_drawer > div {
    width: 600px;
    padding: 20px;
    background: #1a1e21;
}

.banner_drawer .drawer_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.banner_drawer .drawer_list > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.banner_drawer .drawer_list > div > span {
    width: 150px;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: left;
}

.banner_drawer .drawer_list > div > p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff9c;
}

.banner_drawer .drawer_list > .image_section {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.banner_drawer .drawer_list > .image_section > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.banner_drawer .drawer_list > .image_section > div span {
    width: 150px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #FFF;
    text-align: left;
}

.banner_drawer .drawer_list > .image_section > div .image {
    width: 100px;
    height: 80px;
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
