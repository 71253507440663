.login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.card_div .card_login {
    background-color: #000;
    padding: 40px;
    border-radius: 5px;
}

.fields_section .text_field > div {
    padding: 0 !important;
    background: #262729;
    border-radius: 5px;
    font-weight: normal;
    font-size: 18px;
    line-height: 154.41%;
    text-align: center;
    color: #606060;
    opacity: 0.8;
    width: 400px;
}

.fields_section .text_field > div {
    padding-left: 0 !important;
}

.fields_section .text_field > div > div {
    margin-top: 0 !important;
    margin-right: 0 !important;
}

.fields_section .text_field > div::before, .fields_section .text_field > div::after {
    border-bottom: none !important;
}

.fields_section .text_field > div input {
    padding: 10px;
    height: 28px;
    border-radius: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 154.41%;
    color: #a7a7a7;
    caret-color: #FAC01E;
}

.fields_section .text_field > p {
    color: #c01a1a;
    margin-left: 5px;
}

.fields_section .icons_login {
    background: #FAC01E;
    border-radius: 5px 0 0 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 50px;
    max-height: 50px;
    max-width: 50px;
    min-width: 50px;
}

.fields_section .icons_login svg {
    width: 24px;
    height: 24px;
}

.login_action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 40px 0;
}

.login .login_action > button {
    color: #6c6969;
    width: 25%;
    background: #0e0f11;
    border: 1px solid #FAC01E;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 38px;
}

.login_action > button:hover {
    background: #FAC01E;
    color: #fff;
}

/* switch button */
.card_div .switch_button {
    width: 100%;
}

.card_div .switch_button label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    color: rgb(255, 255, 255);
    margin-top: 20px;
    margin-left: 0;
}

.card_div .switch_button label span:nth-child(2) {
    font-size: 20px;
}

/*.card_div .switch_button label > span:nth-child(1) > span:nth-child(2) {*/
/*    background: #cdd1e4;*/
/*}*/
