.default_thumbnail img {
    width: 100%;
}

.dummy_image {
    background-size: cover;

    width: 100px;
    height: 80px;
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.collection_icon {
    width: 100px;
    height: 80px;
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
